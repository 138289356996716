import {
    buildMapToField,
    handleQueryResolve
} from '../utils'

const uniqBy = require('lodash/uniqBy')
const keyBy = require('lodash/keyBy')

export default function (PatientID) {
  // classic JS with hoist and scope
  // FIXME break those function to their own modules and call them with this[query]
  const _vm = this

  return Promise.all([
    queryPatientProfile(PatientID),

    // FIXME might need to fix things
    // queryPharmacyInfo(), // we dont have pharmacy info in kroll
    queryPatientMedication(PatientID),
    queryPatientDoctor(PatientID),
  ]).then(patientRecords => patientRecords.reduce((patientData, patientRecord) => ({
    ...patientData,
    ...patientRecord,
  }), {}), err => {
    console.error(err)
  })

  ///
  function queryPatientMedication(PatientID) {
    // extract Unqiue DINs of Patient
    return _vm.query(`
    SELECT
    top 1 with ties Rx.RxNum AS RxNumber,
    Rx.FillDate AS RxDate,
    Rx.DIN,
    Rx.Status,
    Drg.GenericName AS GenericName,
    Drg.Strength AS Strength,
    Drg.BrandName AS BrandName,
    DrgFormMast.Form AS Form,
    Drg.Description AS Description,
    Rx.DispQty AS RxQtyDispense,
    Rx.DispQty AS QtyDispense,
    Rx.RemQty AS QtyRemain,
    Rx.AuthQty as TotalAuthorized,
    Rx.SIG AS SIGFULL,
    Rx.PatID AS PatientID,
    Rx.DocID,
    rx.DaysSupply as Days,
    Doc.Licence1 as CPSO,
    Doc.LastName,
    Doc.FirstName,
    DoctorFax.Phone as DoctorFax,
    DoctorPhone.Phone as DoctorPhone,
    null as Brk,
    null as Noon,
    null as Supp,
    null as Bed,
    null as IsOTC,
    rx.Inactive as Inactive
FROM
    Pharmacy.dbo.Rx
    INNER JOIN Pharmacy.dbo.Drg ON Rx.DrgID = Drg.ID
    INNER JOIN Pharmacy.dbo.Doc ON Rx.DocID = Doc.ID
    INNER JOIN Pharmacy.dbo.DrgFormMast ON Drg.DrgFormId = DrgFormMast.Id
    LEFT JOIN Pharmacy.dbo.DocPhone as DoctorFax ON Doc.ID = DoctorFax.DocID
    AND DoctorFax.Type = '2'
    LEFT JOIN Pharmacy.dbo.DocPhone as DoctorPhone ON Doc.ID = DoctorPhone.DocID
    AND DoctorPhone.Type = '1'
WHERE
    Rx.FillDate > DATEADD(month, -12, GetDate())
    AND (
        Rx.Status <> '2'
        OR Rx.Status <> '3'
        OR Rx.Status is null
    )
    AND rx.PatID = @PatientID
ORDER BY
    row_number() over (
        partition by Drg.GenericName,
        Drg.Strength
        order by
            FillDate DESC
    )
        `, [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID, // to support like
    }])
      .then(handleQueryResolve)
      .then(medicationResults => {
        const listOfNonRepeatedMeds = uniqBy(medicationResults, 'DIN').map((record, index) => ({
          // [`ID`]: key,
          DIN: record.DIN,
          RxNumber: record.RxNumber,
          RxDate: record.RxDate,
          QtyDispense: record.QtyDispense,
          QtyRemain: record.QtyRemain,
          Days: record.Days,
          'RX?': record.Schedule == '1' ? 'Yes' : '',
          'NHP?': '',
          GenericName: record.GenericName,
          'OTC?': record.IsOTC == '1' ? 'Yes' : '',
          onHold: record.Hold == '1' ? 'Yes' : '',
          Status: record.Status,
          MED: record.BrandName,
          'MED STR': record.Strength,
          FORM: record.Form,
          SIG: record.SIGFULL,
          INDICATION: '',
          'MED AM QTY': record.Brk,
          'MED LUNCH QTY': record.Noon,
          'MED DINNER QTY': record.Supp,
          'MED BEDTIME QTY': record.Bed,
          'MED OTHER QTY': '',
          'ADHERENCE YES': '',
          'ADHERENCE NO': '',
          'PATIENT COMMENT': '',
          'MED PHARMACIST NOTES': '',
          'COMMENTS FOR MEDSCHECK RECORD': record.Description ? [record.Description.toUpperCase()] : '',
        }))

        return {
          medications: uniqBy(listOfNonRepeatedMeds, med => [med.GenericName, med['MED STR']].join()),
        }
      })
      .then(medicationsInst =>

      /*
                                                                                                                    ,[Id]
                                                                                                                    ,[Language]
                                                                                                                    ,[QtyLow]
                                                                                                                    ,[QtyHigh]
                                                                                                                    ,[FrequencyNumerator]
                                                                                                                    ,[FrequencyDenominator1]
                                                                                                                    ,[FrequencyDenominator2]
                                                                                                                    ,[FrequencyDenominatorUnits]
                                                                                                                    ,[Duration]
                                                                                                                    ,[IsCondition]
                                                                                                                    ,[Condition]
                                                                                                                    ,[IsSupplementalInstruction]
                                                                                                                    ,[SupplementalInstruction]
                                                                                                                    ,[IsRouteOfAdmin]
                                                                                                                    ,[IsMaxDosage]
                                                                                                                    ,[MaxDoseQty]
                                                                                                                    ,[MaxDoseQtyUnit]
                                                                                                                    ,[MaxDoseRange]
                                                                                                                    ,[MaxDoseRangeUnit]
                                                                                                                */
        _vm.query(`
                SELECT 
                    [Token],
                    [Text]
                FROM Pharmacy.dbo.Sig
                WHERE Language = 'E'
            `)
          .then(handleQueryResolve)
          .then(tokens => {
            // to translate short sig to full sig
            const tokenMap = keyBy(tokens, o => o.Token)
            medicationsInst.medications = medicationsInst.medications.map(medication => {
              const sig = medication.SIG

              // do nothing when sig is null
              // GenericName: "Pharmaceutical Opinion Program" ( a service doesn't need a sig)
              if (!sig) {
                return medication
              }

              // // logic to translate tokens
              // "SIGFULL": "T 1 GELCAP PO BID"
              // "SIGFULL": "UD"
              let newSigDescriptionA
              let newSigDescription
              if (sig.indexOf(' ') > -1) {
                newSigDescriptionA = sig.split(' ').map(token => _getSigToken(token, tokenMap)).join(' ')
                newSigDescription = newSigDescriptionA.split(' ').map(token => _getSigToken(token, tokenMap)).join(' ')
              } else {
                newSigDescription = _getSigToken(sig, tokenMap)
              }
              medication.SIG = newSigDescription

              // // end of full sig
              return medication
            })

            return medicationsInst
          }))
  }

  function _getSigToken(token, tokenMap) {
    const tokenInst = tokenMap[token]
    if (tokenInst) {
      return tokenInst.Text
    }

    return token
  }

  // TODO OCP Pharmacist's # is under "USERS"
  function queryPharmacyInfo() {
    // FIXME you cant query Fillware
    return _vm.query(`
            SELECT DISTINCT
                sParameterName,
                sParameterValue
            FROM
                Fillware.dbo.SystemParameters
            WHERE
                (
                    sParameterName = 'PharmacyName'
                    OR sParameterName = 'Address'
                    OR sParameterName = 'City'
                    OR sParameterName = 'Province'
                    OR sParameterName = 'Postal'
                    OR sParameterName = 'Fax'
                    OR sParameterName = 'Phone'
                )
            AND
                (
                    sParameterValue IS NOT NULL OR sParameterValue != ''
                )
        `, [])
      .then(handleQueryResolve)
      .then(recordset => {
        const MapValuesTo = {
          PharmacyName: 'PharmacyName',
          Address: 'PharmacyAddress',
          City: 'PharmacyCity',
          Province: 'PharmacyProvince',
          Postal: 'PharmacyPostal',
          Fax: 'PharmacyFax',
          Phone: 'PharmacyPhone',
        }

        const whiteList = Object.keys(MapValuesTo)

        const pharmacyInfo = recordset.reduce((cur, row) => {
          if (whiteList.indexOf(row.sParameterName) == -1 || !row.sParameterValue) {
            return cur
          }

          return {
            ...cur,
            [MapValuesTo[row.sParameterName]]: row.sParameterValue,
          }
        }, {})

        return buildMapToField(pharmacyInfo)
      })
  }

  function queryPatientProfile(PatientID) {
    return _vm.query(`
            SELECT
                patient.ID as PatientID,
                patient.FirstName,
                patient.LastName,
                null as FamilyDoc,
                (SELECT TOP(1) ClientID FROM Pharmacy.dbo.PatPln WHERE PatID = @PatientID AND SubPlanID = 185) as OHIP,
                patient.Address1 as Address,
                patient.City,
                patient.Prov as Province,
                patient.Postal,
                patient.EMail,
                (SELECT TOP(1) Phone FROM Pharmacy.dbo.PatPhone WHERE PatID = @PatientID) as HomePhone,
                null as Allergies,
                patient.Birthday as DOB,
                patient.Sex as Gender,
                null as LastVisit,
                null as MedsCheckReminder
            FROM
                Pharmacy.dbo.Pat as patient
            WHERE
                patient.ID = @PatientID
            `, [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID,
    }])
      .then(handleQueryResolve, e => {
        console.log(e)
      })
      .then(recordset => buildMapToField(recordset[0]))
  }

  // Primary Provider is the top doctor
  function queryPatientDoctor(PatientID) {
    return _vm.query(`
            SELECT TOP(2)
                COUNT(Rx.DocID) AS Expr1,
                Doc.LastName,
                Doc.FirstName,
                Doc.Licence1 AS CPSO,
                DocPhone.Phone,
                DocPhone.Type
            FROM
                Pharmacy.dbo.Rx
            INNER JOIN Pharmacy.dbo.Doc ON Rx.DocID = Doc.ID
            INNER JOIN Pharmacy.dbo.DocPhone ON Doc.ID = DocPhone.DocID
            WHERE
                Rx.PatID = @PatientID
            GROUP BY
                Doc.LastName,
                Doc.FirstName,
                Doc.Licence1,
                DocPhone.Phone,
                DocPhone.Type
            ORDER BY Expr1 DESC
        `, [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID,
    }])
      .then(handleQueryResolve)
      .then(recordset => {
        if (recordset.length) {
          const doctorPhoneRecord = recordset[0]
          let doctorFaxRecord = ''

          // check for fax since its type 2
          if (
            recordset.length > 1
                        && recordset[1].FirstName
                        && recordset[1].LastName
          ) {
            doctorFaxRecord = recordset[1]
          }

          // build record object
          const record = {
            DoctorFirstName: doctorPhoneRecord.FirstName,
            DoctorLastName: doctorPhoneRecord.LastName,
            CPSO: doctorPhoneRecord.CPSO,
            DoctorEmail: '',
            DoctorPhone: doctorPhoneRecord.Phone,
            DoctorFax: doctorFaxRecord.Phone,
          }

          return buildMapToField(record)
        }

        return {}
      }, err => {
        console.error(err)
      })
  }
}
